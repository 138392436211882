import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./components/AdminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";

export function Managecategorys() {
    const [isLoading, setLoading] = useState(false);
    const [categoryid, setcategoryid] = useState("");
    const [categoryName, setcategoryName] = useState("");
    const [categoryImage, setcategoryImage] = useState("");
    const [categoryList, setcategoryList] = useState([]);

    const [addForm, setaddForm] = useState(true);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listcategory", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setcategoryList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addcategory() {
        setLoading(true);
        const data = new FormData();
        data.append("name", categoryName);
        data.append("image", categoryImage);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=insertcategory", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "category added successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly or category already exists.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or category already exists.");
        });
    }

    function editcategory() {
        setLoading(true);
        const data = new FormData();
        data.append("id", categoryid);
        data.append("name", categoryName);
        data.append("image", categoryImage);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=editcategory", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "category edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deletecategory(id) {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=deletecategory", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "category deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                {addForm == true ? 
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Category</p>
                        <input value={categoryName} onChange={(e) => {
                            setcategoryName(e.target.value);
                        }} className="form-control mb-2" placeholder="Category Name"/>
                        <input value={categoryImage} onChange={(e) => {
                            setcategoryImage(e.target.value);
                        }} className="form-control mb-2" placeholder="Image Link"/>
                        <button onClick={addcategory} className="btn btn-primary w-100">Save</button>
                    </div>
                : <div className="card p-3" >
                    <p className="fs-5 text-center">Edit Category</p>
                    <input value={categoryName} onChange={(e) => {
                        setcategoryName(e.target.value);
                    }} className="form-control mb-2" placeholder="Category Name"/>
                    <input value={categoryImage} onChange={(e) => {
                        setcategoryImage(e.target.value);
                    }} className="form-control mb-2" placeholder="Image Link"/>
                    <button onClick={editcategory} className="btn btn-primary w-100 mb-2">Save</button>
                    <button onClick={() => {
                        setcategoryid("");
                        setcategoryImage("");
                        setcategoryName("")
                        setaddForm(true)
                    }} className="btn btn-danger w-100">Close</button>
                </div>
            }
                
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Category Name</th>
                                    <th>Image Link</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categoryList.map((category, index) => (
                                    <tr key={index}>
                                        <td>{category.name}</td>
                                        <td>{category.image}</td>
                                        <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setcategoryid(category.id);
                                                    setcategoryName(category.name);
                                                    setcategoryImage(category.image);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deletecategory(category.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
