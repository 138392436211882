import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./components/AdminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";
import { Book } from '@mui/icons-material';
import { format } from 'date-fns';

export function ManageBookings() {
    const [isLoading, setLoading] = useState(false);
    const [Bookingid, setBookingid] = useState("");
    const [BookingName, setBookingName] = useState("");
    const [BookingPrice, setBookingPrice] = useState("");
    const [BookingDescription, setBookingDescription] = useState("");
    const [BookingCategoryId, setBookingCategoryId] = useState("");
    const [BookingList, setBookingList] = useState([]);
    const [addForm, setaddForm] = useState(true);
    const [Categoryid, setCategoryid] = useState("");
    const [categoryList, setcategoryList] = useState([]);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listcategory", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setcategoryList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, [])

    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listBooking", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setBookingList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addBooking() {
        setLoading(true);
        const data = new FormData();
        data.append("name", BookingName);
        data.append("price", BookingPrice);
        data.append("description", BookingDescription);
        data.append("category", BookingCategoryId);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=insertBooking", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Booking added successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly or Booking already exists.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or Booking already exists.");
        });
    }

    function editBooking() {
        setLoading(true);
        const data = new FormData();
        data.append("id", Bookingid);
        data.append("name", BookingName);
        data.append("price", BookingPrice);
        data.append("description", BookingDescription);
        data.append("category", BookingCategoryId);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=editBooking", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Booking edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deleteBooking(id) {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=deleteBooking", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Booking deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    const [onlineWorkers, setOnlineWorkers] = useState([]);

    async function AssignWorker(bookingID) {
        setLoading(true);
        try {
            const response = await fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=fetch_online_workers", {
                method: 'GET'
            });
            const data = await response.json();
    
            if (data.status) {
                const workers = data.data;
                setOnlineWorkers(workers);  // Setting the state with the whole workers array
                setLoading(false);
    
                // Create worker options directly from the fetched data
                const workerOptions = {};
                workers.forEach(worker => {
                    workerOptions[worker.uid] = worker.full_name;  // Use `uid` for worker ID and `full_name` for the name
                });
    
                console.log(workerOptions);  // For debugging purposes
    
                const result = await Swal.fire({
                    title: 'Select Worker',
                    input: 'select',
                    inputOptions: workerOptions,
                    inputPlaceholder: 'Select a worker to assign',
                    showCancelButton: true,
                    inputValidator: function (value) {
                        return new Promise(function (resolve) {
                            if (value !== '') {
                                resolve();
                            } else {
                                resolve('You need to select a worker');
                            }
                        });
                    }
                });
    
                if (result.isConfirmed) {
                    // Handle worker selection
                    const data = new FormData();
                    data.append("workerUID", result.value);
                    data.append("bookingID", bookingID);
                    fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=assignWorker", {
                        method: 'POST',
                        body: data
                    })
                    .then(function(res) { return res.json() })
                    .then(function(data) {
                        if (data.status === true) {
                            Swal.fire('success', 'Worker assigned successfully.', 'success');
                            setTimeout(() => {window.location.reload()}, 2000)
                        }
                    })
                }
            } else {
                setLoading(false);
                Swal.fire('warning', 'No worker selected', 'error');
            }
        } catch (error) {
            setLoading(false);
            Swal.fire('Error', 'An error occurred', 'error');
        }
    }
    
    
    

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Patient Details</th>
                                    <th>Pickup Details</th>
                                    <th>Worker</th>
                                    <th>Amount</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {BookingList.map((Booking, index) => {
                                    const date = new Date(`${Booking.date}T${Booking.time}`);
                                    const formattedDate = Booking.date
                                    const formattedTime = Booking.time
                                    
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <b>Name:</b> {JSON.parse(Booking.patient_details).name}<br />
                                                <b>Phone:</b> {JSON.parse(Booking.patient_details).phone}<br />
                                                <b>Gender:</b> {JSON.parse(Booking.patient_details).gender}<br />
                                                <b>Referring Doctor:</b> {JSON.parse(Booking.patient_details).referringDoctor}<br />
                                            </td>
                                            <td>
                                                <b>Date:</b> {formattedDate}<br />
                                                <b>Time:</b> {formattedTime}<br />
                                            </td>
                                            <td>{Booking.assigned_worker_name == undefined ? 
                                                <button 
                                                onClick={() => {
                                                    AssignWorker(Booking.id);
                                                }}
                                                className='btn btn-primary'>Assign Worker</button>
                                                : 
                                                <p>{Booking.assigned_worker_name}</p>
                                                }</td>
                                            <td>₹{Booking.amount} ({Booking.payment})</td>
                                            <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                   window.open(Booking.prescription, '_blank');
                                                }}>Prescription</button>
                                                <button className='btn btn-success'  onClick={() => {
                                                   const googleMapsURL = `https://www.google.com/maps?q=${Booking.lat},${Booking.lng}`;
                                                   window.open(googleMapsURL, '_blank');
                                                }}>Location</button>
                                                <button className='btn btn-danger' onClick={() => {
                                                    const data = new FormData();
                                                    data.append("bookingID", Booking.id);
                                                    fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=fetch_tests", {
                                                        method: 'POST',
                                                        body: data
                                                    })
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        const tests = [];
                                                        data["data"].forEach((test) => {
                                                            tests.push(test.name);
                                                        })
                                                        alert(tests);
                                                    })
                                                }}>Tests</button>
                                            </div>
                                        </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
