import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvKI3nZkglQ77IR_O8csCHVUiUJrQMYag",
  authDomain: "pathologyshare-35e8c.firebaseapp.com",
  databaseURL: "https://pathologyshare-35e8c-default-rtdb.firebaseio.com",
  projectId: "pathologyshare-35e8c",
  storageBucket: "pathologyshare-35e8c.appspot.com",
  appId: "1:708260293774:web:582caa6e014a8c79456ddf",
  measurementId: "G-223QDRYV86"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const LiveLocationTracker = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [watchId, setWatchId] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('worker')) {
      if ('geolocation' in navigator) {
        const id = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });

            // Update location in Firebase
            const locationRef = ref(database, 'location/' + localStorage.getItem("workerUID"));
            set(locationRef, { latitude, longitude });
          },
          (error) => {
            setError(error.message);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
        setWatchId(id);
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    }

    return () => {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [watchId]);

  return (
    <div>
      {/* <h1>Live Location Tracker</h1>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <p>
          Latitude: {location.latitude}, Longitude: {location.longitude}
        </p> 
      )}*/}
    </div>
  );
};

export default LiveLocationTracker;
