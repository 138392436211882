// IsLogin.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function IsLogin() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state to handle the initial check

    useEffect(() => {
        const userUID = localStorage.getItem("userUID");
        const hospitalUID = localStorage.getItem("hospitalUID");
        if (userUID || hospitalUID) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                if (!isLogin) {
                    if(window.location.pathname !== "hospital") {
                        navigate("/login");
                    }
                } else {
                    if(window.location.pathname === "/") {
                        navigate("/home");
                    }
                    if(window.location.pathname === "/login") {
                        navigate("/home");
                    }
                }
            }, 1500)
        }
    }, [isLoading, isLogin, navigate]);

    return null;
}

export default IsLogin;
