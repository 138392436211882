import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./components/AdminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";

export function Managehospitals() {
    const [isLoading, setLoading] = useState(false);
    const [hospitalid, sethospitalid] = useState("");
    const [hospitalName, sethospitalName] = useState("");
    const [hospitalEmail, sethospitalEmail] = useState("");
    const [hospitalPassword, sethospitalPassword] = useState("");
    const [hospitalStatus, sethospitalStatus] = useState("Yes");
    const [hospitalList, sethospitalList] = useState([]);

    const [addForm, setaddForm] = useState(true);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };


    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listhospital", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            sethospitalList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addhospital() {
        setLoading(true);
        const data = new FormData();
        data.append("email", hospitalEmail);
        data.append("password", hospitalPassword);
        data.append("full_name", hospitalName);
        data.append("is_price_visible", hospitalStatus);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=inserthospital", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "hospital added successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly or hospital already exists.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or hospital already exists.");
        });
    }

    function edithospital() {
        setLoading(true);
        const data = new FormData();
        data.append("id", hospitalid);
        data.append("email", hospitalEmail);
        data.append("password", hospitalPassword);
        data.append("full_name", hospitalName);
        data.append("is_price_visible", hospitalStatus);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=edithospital", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "hospital edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deletehospital(id) {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=deletehospital", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "hospital deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                {addForm == true ? 
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add hospital</p>
                        <input value={hospitalName} onChange={(e) => {
                            sethospitalName(e.target.value);
                        }} className="form-control mb-2" placeholder="Hospital Name"/>
                        <input value={hospitalEmail} onChange={(e) => {
                            sethospitalEmail(e.target.value);
                        }} className="form-control mb-2" placeholder="Email"/>
                        <input value={hospitalPassword} onChange={(e) => {
                            sethospitalPassword(e.target.value);
                        }} className="form-control mb-2" placeholder="Password"/>
                        <select value={hospitalStatus} onChange={(e) => {
                            sethospitalStatus(e.target.value);
                        }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                            <option>Yes</option>
                            <option>No</option>
                        </select>
                        <p>Select "Yes" if you want to show prices to hospital and "No" if you do not want to show
                            prices to hospitals.
                        </p>
                        <button onClick={addhospital} className="btn btn-primary w-100">Save</button>
                    </div>
                : <div className="card p-3" >
                    <p className="fs-5 text-center">Edit hospital</p>
                    <input value={hospitalName} onChange={(e) => {
                        sethospitalName(e.target.value);
                    }} className="form-control mb-2" placeholder="Full Name"/>
                    <input value={hospitalEmail} onChange={(e) => {
                        sethospitalEmail(e.target.value);
                    }} className="form-control mb-2" placeholder="Email"/>
                    <input value={hospitalPassword} onChange={(e) => {
                        sethospitalPassword(e.target.value);
                    }} className="form-control mb-2" placeholder="Password"/>
                    <select value={hospitalStatus} onChange={(e) => {
                        sethospitalStatus(e.target.value);
                    }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                        <option>Yes</option>
                        <option>No</option>
                    </select>
                    <p>Select "Yes" if you want to show prices to hospital and "No" if you do not want to show
                            prices to hospitals.
                        </p>
                    <button onClick={edithospital} className="btn btn-primary w-100 mb-2">Save</button>
                    <button onClick={() => {
                        sethospitalid("");
                        sethospitalEmail("");
                        sethospitalName("")
                        sethospitalPassword("");
                        setaddForm(true)
                    }} className="btn btn-danger w-100">Close</button>
                </div>
            }
                
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Hospital Name</th>
                                    <th>Is Price Visible</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hospitalList.map((hospital, index) => (
                                    <tr key={index}>
                                        <td>{hospital.email}</td>
                                        <td>{hospital.name}</td>
                                        <td>{hospital.is_price_visible}</td>
                                        <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    sethospitalid(hospital.uid);
                                                    sethospitalName(hospital.name);
                                                    sethospitalPassword(hospital.password);
                                                    sethospitalStatus(hospital.is_price_visible);
                                                    sethospitalEmail(hospital.email);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deletehospital(hospital.uid)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
