import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./components/AdminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";

export function ManageCoupons() {
    const [isLoading, setLoading] = useState(false);
    const [Couponid, setCouponid] = useState("");
    const [CouponName, setCouponName] = useState("");
    const [CouponAmount, setCouponAmount] = useState("");
    const [CouponPassword, setCouponPassword] = useState("");
    const [CouponStatus, setCouponStatus] = useState("Online");
    const [CouponList, setCouponList] = useState([]);

    const [addForm, setaddForm] = useState(true);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listCoupons", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setCouponList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addCoupon() {
        setLoading(true);
        
        const data = new FormData();
        data.append("code", CouponName);
        data.append("amount", CouponAmount);
        data.append("status", CouponStatus);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=insertCoupon", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Coupon added successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly or Coupon already exists.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or Coupon already exists.");
        });
    }

    function editCoupon() {
        setLoading(true);
        const data = new FormData();
        data.append("id", Couponid);
        data.append("code", CouponName);
        data.append("amount", CouponAmount);
        data.append("status", CouponStatus);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=editCoupon", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Coupon edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deleteCoupon(id) {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=deleteCoupon", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Coupon deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                {addForm == true ? 
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Coupon</p>
                        <input value={CouponName} onChange={(e) => {
                            setCouponName(e.target.value);
                        }} className="form-control mb-2" placeholder="Coupon Code"/>
                        <input value={CouponAmount} onChange={(e) => {
                            setCouponAmount(e.target.value);
                        }} className="form-control mb-2" placeholder="Amount"/>
                        <select value={CouponStatus} onChange={(e) => {
                            setCouponStatus(e.target.value);
                        }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                            <option>Choose Status</option>
                            <option value={1}>Enable</option>
                            <option value={0}>Disable</option>
                        </select>
                        <button onClick={addCoupon} className="btn btn-primary w-100">Save</button>
                    </div>
                : <div className="card p-3" >
                    <p className="fs-5 text-center">Edit Coupon</p>
                    <input value={CouponName} onChange={(e) => {
                        setCouponName(e.target.value);
                    }} className="form-control mb-2" placeholder="Coupon Code"/>
                    <input value={CouponAmount} onChange={(e) => {
                        setCouponAmount(e.target.value);
                    }} className="form-control mb-2" placeholder="Amount"/>
                    <select value={CouponStatus} onChange={(e) => {
                        setCouponStatus(e.target.value);
                    }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                        <option>Choose Status</option>
                        <option value={1}>Enable</option>
                        <option value={0}>Disable</option>
                    </select>
                    <button onClick={editCoupon} className="btn btn-primary w-100 mb-2">Save</button>
                    <button onClick={() => {
                        setCouponid("");
                        setCouponAmount("");
                        setCouponName("")
                        setCouponPassword("");
                        setaddForm(true)
                    }} className="btn btn-danger w-100">Close</button>
                </div>
            }
                
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Coupon Code</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {CouponList.map((Coupon, index) => (
                                    <tr key={index}>
                                        <td>{Coupon.code}</td>
                                        <td>₹{Coupon.amount}</td>
                                        <td>{Coupon.status}</td>
                                        <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setCouponid(Coupon.id);
                                                    setCouponName(Coupon.code);
                                                    setCouponStatus(Coupon.status);
                                                    setCouponAmount(Coupon.amount);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deleteCoupon(Coupon.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
