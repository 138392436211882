import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./components/AdminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";

export function Managetests() {
    const [isLoading, setLoading] = useState(false);
    const [testid, settestid] = useState("");
    const [testName, settestName] = useState("");
    const [testPrice, settestPrice] = useState("");
    const [testDescription, settestDescription] = useState("");
    const [testCategoryId, settestCategoryId] = useState("");
    const [testList, settestList] = useState([]);
    const [addForm, setaddForm] = useState(true);
    const [Categoryid, setCategoryid] = useState("");
    const [categoryList, setcategoryList] = useState([]);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listcategory", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            setcategoryList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, [])

    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=listtest", {
            method: "GET"
        })
        .then((response) => response.json())
        .then((data) => {
            settestList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addtest() {
        setLoading(true);
        const data = new FormData();
        data.append("name", testName);
        data.append("price", testPrice);
        data.append("description", testDescription);
        data.append("category", testCategoryId);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=inserttest", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "test added successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly or test already exists.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or test already exists.");
        });
    }

    function edittest() {
        setLoading(true);
        const data = new FormData();
        data.append("id", testid);
        data.append("name", testName);
        data.append("price", testPrice);
        data.append("description", testDescription);
        data.append("category", testCategoryId);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=edittest", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "test edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deletetest(id) {
        setLoading(true);
        const data = new FormData();
        data.append("id", id);

        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=deletetest", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "test deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                {addForm == true ? 
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Test</p>
                        <input value={testName} onChange={(e) => {
                            settestName(e.target.value);
                        }} className="form-control mb-2" placeholder="Test Name"/>
                        <input value={testPrice} onChange={(e) => {
                            settestPrice(e.target.value);
                        }} className="form-control mb-2" placeholder="Price"/>
                        <input value={testDescription} onChange={(e) => {
                            settestDescription(e.target.value);
                        }} className="form-control mb-2" placeholder="Description (Optional)"/>
                        <select value={Categoryid} onChange={(e) => {
                            settestCategoryId(e.target.value);
                        }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                            {categoryList.map((category) => {
                                return <option value={category.id}>{category.name}</option>
                            })}
                        </select>
                        <button onClick={addtest} className="btn btn-primary w-100">Save</button>
                    </div>
                : <div className="card p-3" >
                    <p className="fs-5 text-center">Edit test</p>
                    <input value={testName} onChange={(e) => {
                            settestName(e.target.value);
                        }} className="form-control mb-2" placeholder="Test Name"/>
                        <input value={testPrice} onChange={(e) => {
                            settestPrice(e.target.value);
                        }} className="form-control mb-2" placeholder="Price"/>
                        <input value={testDescription} onChange={(e) => {
                            settestDescription(e.target.value);
                        }} className="form-control mb-2" placeholder="Description (Optional)"/>
                        <select value={Categoryid} onChange={(e) => {
                            settestCategoryId(e.target.value);
                        }} className="bg-white p-2 w-100 border rounded-2 mb-3">
                            {categoryList.map((category) => {
                                return <option value={category.id}>{category.name}</option>
                            })}
                        </select>
                    <button onClick={edittest} className="btn btn-primary w-100 mb-2">Save</button>
                    <button onClick={() => {
                        settestid("");
                        settestName("");
                        settestDescription("");
                        settestPrice("");
                        setaddForm(true);
                    }} className="btn btn-danger w-100">Close</button>
                </div>
            }
                
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Test Name</th>
                                    <th>Price</th>
                                    <th>Description</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testList.map((test, index) => (
                                    <tr key={index}>
                                        <td>{test.name}</td>
                                        <td>{test.price}</td>
                                        <td>{test.description}</td>
                                        <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    settestid(test.id);
                                                    settestName(test.name);
                                                    settestPrice(test.price)
                                                    settestDescription(test.description);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deletetest(test.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
