import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import { API_URL } from '../App';

export function LoginAdmin(){ 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    return (
        <div className="container justify-content-center align-items-center">
            {/* Just to give some space, I have added this div class*/}
            <div className="mb-4"></div>
            <div className="card p-4">
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control mb-2" placeholder="Email" type="text"/>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mb-3" placeholder="Password" type="text" maxLength="20"/>
                <button
                    onClick={() => {
                        const data = new FormData();
                        data.append("email", email);
                        data.append("password", password);
                        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=login", {
                            method: "POST",
                            body: data
                        })
                        .then(response => response.json())
                        .then(data => {
                            if(data.status == true) {
                                localStorage.setItem("key", data.key);
                                navigate("/admin/dashboard");
                            } else {
                                alert("Invalid Credentials");
                            }
                        })
                    }}
                    className="btn btn-outline-success text-center">Login
                </button>
            </div>
            <div id="recaptcha-container"></div>
        </div>
    )
}