import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const MapComponent = ({ onAddressChange, onLatChange, onLngChange }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAQdpGLVu7w5DOUqkKucbfoqu59OPJ2oHc'
    });

    const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [initialLocationSet, setInitialLocationSet] = useState(false);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                if (!initialLocationSet) {
                    setMarkerPosition({ lat: latitude, lng: longitude });
                    setMapCenter({ lat: latitude, lng: longitude });
                    setInitialLocationSet(true);

                    onLatChange(latitude);
                    onLngChange(longitude);

                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
                        if (status === 'OK' && results[0]) {
                            onAddressChange(results[0].formatted_address);
                        } else {
                            console.error('Geocoder failed due to: ' + status);
                        }
                    });
                }
            }, () => {
                if (!initialLocationSet) {
                    const defaultPosition = { lat: -3.745, lng: -38.523 };
                    setMapCenter(defaultPosition);
                    setMarkerPosition(defaultPosition);
                    setInitialLocationSet(true);
                }
            });
        } else {
            if (!initialLocationSet) {
                const defaultPosition = { lat: -3.745, lng: -38.523 };
                setMapCenter(defaultPosition);
                setMarkerPosition(defaultPosition);
                setInitialLocationSet(true);
            }
        }
    }, [onAddressChange, onLatChange, onLngChange, initialLocationSet]);

    const handleLocationChange = useCallback((lat, lng) => {
        setMarkerPosition({ lat, lng });
        setMapCenter({ lat, lng });

        onLatChange(lat);
        onLngChange(lng);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                onAddressChange(results[0].formatted_address);
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }, [onAddressChange, onLatChange, onLngChange]);

    const onMarkerDragEnd = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        handleLocationChange(newLat, newLng);
    }, [handleLocationChange]);

    const onMapClick = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        handleLocationChange(newLat, newLng);
    }, [handleLocationChange]);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={10}
            onClick={onMapClick}
        >
            <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
            />
        </GoogleMap>
    ) : <></>;
};

export default MapComponent;
