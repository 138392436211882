import React, { useEffect, useState, CSSProperties, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {API_URL, Loader} from "../App";
import {useNavigate} from "react-router-dom";
import AdminHeader from "./components/AdminHeader";

export function AdminDashboard() {
    const [loading, setLoading] = useState(false);
    const [hospitals, setHospitals] = useState(0);
    const [workers, setWorkers] = useState(0);

    const [pending, setPending] = useState(0);
    const [picked_up, setPickedUp] = useState(0);
    const [submitted, setSubmitted] = useState(0);
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetch(API_URL + "/admin-functions.php?key=" + localStorage.getItem("key") + "&method=fetch_stats", {
            method: "GET",
        })
        .then(function(res) { return res.json() })
            .then(function(data) {
                if(data.status === true) {
                    // setting users stats
                    setHospitals(data.hospitals);
                    setWorkers(data.workers);
                    // setting booking stats
                    setPending(data.pending);
                    setSubmitted(data.submitted);
                    setPickedUp(data.picked_up);
                    setCompleted(data.completed)
                }
                setLoading(false)
            })
    }, []);

    return(
        <div style={{marginBottom:150}}>
            <AdminHeader />
            <div className="container justify-content-center align-items-center">
                <p className="fs-4 text-center">Statistics</p>
                {/*<div className="card p-3 mb-2">*/}
                {/*    <p className="m-0">Total Users</p>*/}
                {/*    <p className="fs-4 m-0">0</p>*/}
                {/*</div>*/}
                <div className="card p-3 mb-2">
                    <p className="m-0">Total Hospitals</p>
                    <p className="fs-4 m-0">{hospitals}</p>
                </div>
                <div className="card p-3 mb-4">
                    <p className="m-0">Total Workers</p>
                    <p className="fs-4 m-0">{workers}</p>
                </div>
                {/* Booking Stats */}
                <p className="fs-4 text-center">Bookings</p>
                <div className="card p-3 mb-2">
                    <p className="m-0">Pending</p>
                    <p className="fs-4 m-0">{pending}</p>
                </div>
                <div className="card p-3 mb-2">
                    <p className="m-0">Picked Up</p>
                    <p className="fs-4 m-0">{picked_up}</p>
                </div>
                <div className="card p-3 mb-2">
                    <p className="m-0">Submitted to Lab</p>
                    <p className="fs-4 m-0">{submitted}</p>
                </div>
                <div className="card p-3 mb-4">
                    <p className="m-0">Completed</p>
                    <p className="fs-4 m-0">{completed}</p>
                </div>
            </div>
        </div>
    )
}
