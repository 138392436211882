import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../index.css';
import { API_URL, Loader } from "../App";
import Swal from "sweetalert2";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import IsLogin from "../components/IsLogin";

export default function Auth({ setIsLogin }) {
    const navigate = useNavigate();
    // Setting states for the inputs and buttons here
    const [isLoading, setIsLoading] = useState(false);

    // for using google auth
    const responseMessage = (response) => {
        try {
            const decoded = jwtDecode(response.credential);
            const userUID = decoded.sub; // User UID
            localStorage.setItem('userUID', userUID);
            navigate('/home')
            setIsLogin(true);
            // You can now use the user information as needed in your application
        } catch (error) {
            console.error('Error decoding JWT token', error);
        }
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

    };

    // const sendOTP = () => {
    //     setIsLoading(true);
    //     if(phone.length === 10) {
    //     fetch(API_URL + `/authentication.php?method=send&phone=${phone}`, {
    //         method: 'GET',
    //     })
    //         .then((response) => response.json())
    //         .then(function(data) {
    //             console.log(data); // Assuming your server responds with a JSON object containing a "message" field
    //             setIsLoading(false);
    //         })
    //         .catch(function(error) {
    //             showAlert('error', 'Failed to send OTP, kindly try again later. If the issue still comes than contact our support.')
    //             setIsLoading(false);
    //         });
    //     } else {
    //         showAlert('warning', 'Kindly enter a valid phone number.')
    //     }
    // };

    return (
        <div>
            <div className="authcontainer container justify-content-center align-items-center">
                <IsLogin />
                <Loader loading={isLoading} />
                {/* Just to give some space, I have added this div class*/}
                <div className="mb-4"></div>
                <div className='fs-5 text-center mb-1 text-white'>Welcome Back!</div>
                <div className='fs-6 text-center mb-4 text-white'>Get lab tests and reports direct at your door-step. <br /> Powered by Jagruthi Lab.</div>
            </div>

            <div className="auth container card p-4">
                <GoogleLogin
                    onSuccess={responseMessage}
                    onError={errorMessage}
                />
                {/*<input value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control mb-2" placeholder="+91" type="number"/>*/}
                {/*<input value={otp} onChange={(e) => setOtp(e.target.value)} className="form-control mb-3" placeholder="Enter OTP" type="number" maxLength="6"/>*/}
                {/*<button onClick={sendOTP} className="btn btn-outline-primary mb-2">Request OTP</button>*/}
                {/*<button className="btn btn-primary mb-3">Login</button>*/}
                <div className="mb-3"></div>
                <p className="text-center">or</p>
                <button
                    onClick={() => {
                        navigate('/hospital');
                    }}
                    className="btn btn-outline-success text-center p-2">Hospital Login
                </button>
            </div>
            <div id="recaptcha-container"></div>
            <div className='text-center mt-5' style={{ color: "black", opacity: "0.5", position: "fixed", bottom: 15, left: 0, right: 0 }}>By continuing, you agree to out Terms & Condition and Privacy Policy.</div>
        </div>
    )
}