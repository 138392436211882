import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from "./Header";
import {API_URL, Loader} from "../App";
import {useNavigate, useParams} from "react-router-dom";

export default function TestItems() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();
    const [tests, setTests] = useState([]);
    // set if checkout is visible or not, depending upon if cart items price is > 0
    const [if_checkout_visible, setIfCheckoutVisible] = useState("d-none");
    // setting up cart
    const [cart, setCart] = useState(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        return storedCart || [];
    });

    const [totalAmount, setTotalAmount] = useState(localStorage.getItem('total_cart_amount'));

    useEffect(() => {
        // Calculate total amount whenever the cart or tests change
        const total = cart.reduce((sum, itemId) => {
            const item = tests.find(test => test.id === itemId);
            return sum + (item ? parseFloat(item.price) : 0);
        }, 0);
        if(total > 0) { localStorage.setItem("total_cart_amount", total);
            setTotalAmount(localStorage.getItem("total_cart_amount")) }
        if(total > 0) { setIfCheckoutVisible("d-flex") } else { setIfCheckoutVisible("d-none") }
    }, [cart, tests]);

    // adding items to array
    function addToCart(id) {
        const updatedCart = [...cart, id];
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    }

    // removing items from cart
    function removeFromCart(id) {
        const updatedCart = cart.filter(itemId => itemId !== id);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    }

    useEffect(() => {
        setIsLoading(true);
        fetch(API_URL + `/user-functions.php?method=fetch_tests&category_id=${id}` ,{
            method: "GET",
        })
            .then(function(res) { return res.json() })
            .then(function(data) {
                if(data.status === true) {
                    setTests(data.tests);
                } else {
                    setTests([]);
                }
                console.log(tests)
                setIsLoading(false)
            })
    }, [id]);

    return (
        <div>
            <Header/>
            <Loader loading={isLoading}/>
            <div className="container">
                <div className="d-flex flex-wrap gap-3">
                    {tests.length > 0 ? (
                        tests.map((test) => (
                        <div key={test.id} className="card p-4 rounded-5 w-100" style={{ width: '300px' }}>
                        <h6 className="mb-1 fs-5">{test.name}</h6>
                        <p className="text-muted mb-2">{test.description}</p>
                        <div className="d-flex justify-content-between align-items-center">
                            {!cart.includes(test.id) ? (
                                localStorage.getItem("is_price_visible") == "Yes" || localStorage.getItem("is_price_visible") == undefined ? (
                                    <button onClick={() => addToCart(test.id)} className="btn btn-primary fs-6 w-100">Add to Cart : ₹{test.price}</button>
                                ) : (
                                    <button onClick={() => addToCart(test.id)} className="btn btn-primary fs-6 w-100">Add to Cart</button>
                                )
                            ) : (
                                <button onClick={() => removeFromCart(test.id)} className="btn btn-secondary fs-6 w-100">Remove</button>
                            )}
                        </div>
                    </div>
                        ))
                    ) : (
                        <p className="text-muted mb-2 text-center">No tests found</p>
                )}
                </div>
            </div>
        {/*  Showing view cart and checkout  */}
            <div className={`d-flex p-3 bg-white align-items-center ${if_checkout_visible}`}
                 style={{justifyContent:"space-between", position:"fixed", right: 0, left: 0, bottom: 0}}>
                <button className="btn btn-primary w-100" onClick={() => (navigate("/checkout"))}>Checkout</button>
            </div>
        </div>
    )
}
